import styled from "@emotion/styled";

export default styled.article`
  background-color: #fff;
  padding: 2rem 1.2rem;

  & .wrapper {
    max-width: 40em;
    margin: 0 auto;
    padding-top: 1.3em;
    padding-bottom: 1.3em;
  }

  .advert {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    h4 {
      position: relative;
      padding: .9rem 1.2rem 1rem;
      color: ${(props) => props.theme.color.white};
      font-weight: 600;
      font-size: 1.3rem;
      letter-spacing: 0.03rem;
      z-index: 1;

      &:after {
        content: "";
        display: block;
        position: absolute;
        background: ${(props) => props.theme.color.dark};
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        background: ${(props) => props.theme.color.grayMed};
        width: 100%;
        height: 100%;
        top: 10px;
        left: -10px;
        z-index: -1;
      }
    }
  }

    p {
      margin-top: 2rem; 
    }

    a {
    margin: 2em 0em;
    padding: 0.1em 0em;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1em;
    color: black;
    font-weight: 500;
    letter-spacing: 0.12em;
    position: relative;
    transition: color 0.3s ease-in;
    text-decoration: none;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      right: 0;
      background-color: black;
      transform-origin: bottom right;
      transform: scaleX(1);
      transition: transform 0.5s cubic-bezier(0.6, -0.01, 0.11, 0.99);
    }

    &:hover {
      &:after {
        transform: scaleX(0);
        background-color: #c10230;
      }
    }
  }

  /* Changing The Theme */
  &.lightTheme {
    background-color: #fff;
  }
  &.darkTheme {
    background-color: ${(props) => props.theme.color.grayDark};
    color: #fff;
  }
  &.mediumTheme {
    background-color: ${(props) => props.theme.color.light};
  }

  /* Changing The Alignment */
  .wrapper.center {
    text-align: center;
  }
  .wrapper.right {
    text-align: center;
  }
`;
