import React from "react";

const LogoMobile = (props) => {
  return (
    <svg width={172} height={18.32} viewBox="0 0 172 18.32" overflow="visible">
      <style>
        {".logoMobile__st0{fill:#fff}.logoMobile__st2{fill:#86888a}"}
      </style>
      <path
        className="logoMobile__st0"
        d="M39.46 3.3l4.03 7h-8.05l4.02-7zM36.61 0L26.5 17.62h4.71l2.04-3.52h12.41l2.04 3.52h4.71L42.28 0h-5.67zM80.19 8.81c0 1.21-.25 2.35-.74 3.42a8.251 8.251 0 01-2.16 2.81c-.95.8-2.11 1.43-3.49 1.89-1.38.46-2.95.69-4.73.69H56.88V0h12.18c1.78 0 3.35.23 4.73.69 1.38.46 2.54 1.09 3.49 1.89.95.8 1.67 1.73 2.16 2.81.5 1.07.75 2.21.75 3.42m-4.66 0c0-.7-.13-1.37-.39-2-.26-.63-.66-1.18-1.21-1.64-.54-.46-1.23-.83-2.05-1.09-.82-.27-1.8-.4-2.92-.4h-7.65v10.27h7.65c1.12 0 2.1-.14 2.92-.4.82-.27 1.51-.63 2.05-1.09.54-.46.95-1.01 1.21-1.64.26-.64.39-1.31.39-2.01M22.45 13.94v3.67H11.12c-1.78 0-3.35-.23-4.73-.69-1.38-.46-2.54-1.09-3.49-1.89-.95-.8-1.67-1.73-2.16-2.81C.25 11.16 0 10.02 0 8.81s.25-2.35.74-3.42c.49-1.07 1.22-2.01 2.16-2.81.95-.8 2.11-1.43 3.49-1.89C7.77.23 9.35 0 11.12 0h11.33v3.67H11.23c-1.12 0-2.1.13-2.92.4-.83.28-1.51.64-2.06 1.1-.54.46-.94 1.01-1.2 1.64-.26.63-.39 1.3-.39 2s.13 1.37.39 2c.26.63.66 1.17 1.21 1.63.55.46 1.23.83 2.05 1.09.82.27 1.8.4 2.92.4h11.22zM107.38 5.79c0 .77-.1 1.45-.3 2.04-.2.59-.47 1.09-.79 1.52-.33.43-.7.79-1.11 1.07-.41.29-.83.51-1.25.68l3.07 6.52h-4.73l-2.82-6.01h-9.24v6.01h-4.43V0h15.83c.47 0 1.04.08 1.7.25.66.17 1.3.47 1.9.89s1.12 1.02 1.54 1.76c.42.75.63 1.71.63 2.89m-4.43 0c0-.42-.08-.76-.23-1.02-.15-.26-.34-.46-.55-.6-.22-.14-.44-.24-.67-.29a2.8 2.8 0 00-.54-.08H90.21v4h10.75c.1 0 .26-.03.48-.09.22-.06.44-.16.67-.32.23-.15.42-.36.59-.62.16-.25.25-.58.25-.98M113.54 17.62V0h21.21v3.8h-16.78v3.17h14.77v3.42h-14.77v3.43h17.44v3.8z"
      />
      <path
        d="M172 12.11c0 .86-.21 1.66-.63 2.4-.42.75-1.07 1.41-1.95 1.98-.88.57-2 1.02-3.35 1.35-1.35.33-2.96.49-4.82.49-1.39 0-2.67-.14-3.83-.43-1.16-.29-2.18-.64-3.07-1.07-.89-.43-1.64-.9-2.24-1.42-.6-.52-1.06-1.01-1.36-1.48l3.8-1.91c.2.25.52.53.96.83.44.3.96.58 1.57.84.61.26 1.3.47 2.08.64.77.17 1.59.25 2.47.25 1.12 0 2.08-.08 2.86-.24.78-.16 1.41-.36 1.9-.62.49-.25.84-.54 1.06-.87.22-.33.33-.66.33-.99 0-.44-.17-.8-.5-1.09-.34-.29-.79-.53-1.36-.72-.57-.18-1.24-.32-2-.4a23.4 23.4 0 00-2.45-.13c-.77 0-1.57.03-2.39.09a47.982 47.982 0 00-4.78.59c-.76.13-1.45.28-2.09.43L153.35 0h17.21v3.8H156.9l-.28 2.84c.8-.14 1.69-.24 2.65-.33.96-.08 1.95-.13 2.96-.13 1.21 0 2.39.09 3.56.26 1.16.18 2.21.48 3.12.92.91.44 1.66 1.04 2.23 1.8.57.78.86 1.76.86 2.95"
        fill="#c10230"
      />
      <path
        className="logoMobile__st2"
        d="M143.94 17.62c-.97 0-1.76-.75-1.76-1.76 0-.97.78-1.76 1.76-1.76 1.01 0 1.79.79 1.79 1.76.01 1.01-.78 1.76-1.79 1.76M143.94 10.46c-.97 0-1.76-.75-1.76-1.76 0-.97.78-1.76 1.76-1.76 1.01 0 1.79.79 1.79 1.76.01 1.02-.78 1.76-1.79 1.76M143.94 3.52c-.97 0-1.76-.75-1.76-1.76 0-.97.78-1.76 1.76-1.76 1.01 0 1.79.79 1.79 1.76.01 1.01-.78 1.76-1.79 1.76"
      />
    </svg>
  );
};

export default LogoMobile;
