import styled from "@emotion/styled";

export default styled.figure`
  margin: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;
