import React from "react";

const Logo = () => (
  <svg width={18.32} height={172} viewBox="0 0 18.32 172" overflow="visible">
    <style>{".logo__st0{fill:#fff}.logo__st2{fill:#86888a}"}</style>
    <path
      className="logo__st0"
      d="M3.3 132.54l7-4.03v8.05l-7-4.02zM0 135.39l17.62 10.12v-4.71l-3.52-2.04v-12.41l3.52-2.04v-4.71L0 129.72v5.67zM8.81 91.81c1.21 0 2.35.25 3.42.74 1.07.5 2.01 1.22 2.81 2.16.8.95 1.43 2.11 1.89 3.49.46 1.38.69 2.95.69 4.73v12.18H0v-12.18c0-1.78.23-3.35.69-4.73.46-1.38 1.09-2.54 1.89-3.49.8-.95 1.73-1.67 2.81-2.16a8.12 8.12 0 013.42-.74m0 4.66c-.7 0-1.37.13-2 .39-.63.26-1.18.66-1.64 1.21-.46.54-.83 1.23-1.09 2.05-.27.82-.4 1.8-.4 2.92v7.65h10.27v-7.65c0-1.12-.14-2.1-.4-2.92-.27-.82-.63-1.51-1.09-2.05-.46-.54-1.01-.95-1.64-1.21a5.29 5.29 0 00-2.01-.39M13.94 149.55h3.67v11.33c0 1.78-.23 3.35-.69 4.73-.46 1.38-1.09 2.54-1.89 3.49-.8.95-1.73 1.67-2.81 2.16-1.07.5-2.22.74-3.42.74s-2.35-.25-3.42-.74c-1.07-.49-2.01-1.22-2.81-2.16-.8-.95-1.43-2.11-1.89-3.49-.45-1.38-.68-2.96-.68-4.73v-11.33h3.67v11.22c0 1.12.13 2.1.4 2.92.27.82.63 1.51 1.09 2.05.46.54 1.01.95 1.64 1.21.63.26 1.3.39 2 .39s1.37-.13 2-.39c.63-.26 1.17-.66 1.63-1.21.46-.55.83-1.23 1.09-2.05.27-.82.4-1.8.4-2.92v-11.22zM5.79 64.62c.77 0 1.45.1 2.04.3.59.2 1.09.47 1.52.79.43.33.79.7 1.07 1.11.29.41.51.83.68 1.25L17.62 65v4.73l-6.01 2.82v9.24h6.01v4.43H0V70.39c0-.47.08-1.04.25-1.7.17-.66.47-1.3.89-1.9s1.02-1.12 1.76-1.54c.75-.42 1.71-.63 2.89-.63m0 4.43c-.42 0-.76.08-1.02.23-.26.15-.46.34-.6.55-.14.22-.24.44-.29.67a2.8 2.8 0 00-.08.54v10.75h4V71.04c0-.1-.03-.26-.09-.48-.06-.22-.16-.44-.32-.67-.15-.23-.36-.42-.62-.59-.25-.16-.58-.25-.98-.25M17.62 58.46H0V37.25h3.8v16.78h3.17V39.26h3.42v14.77h3.43V36.59h3.8z"
    />
    <path
      d="M12.11 0c.86 0 1.66.21 2.4.63.75.42 1.41 1.07 1.98 1.95.57.88 1.02 2 1.35 3.35.33 1.35.49 2.96.49 4.82 0 1.39-.14 2.67-.43 3.83-.29 1.16-.64 2.18-1.07 3.07-.43.89-.9 1.64-1.42 2.24-.52.6-1.01 1.06-1.48 1.36l-1.91-3.8c.25-.2.53-.52.83-.96.3-.44.58-.96.84-1.57.26-.61.47-1.3.64-2.08.17-.77.25-1.59.25-2.47 0-1.12-.08-2.08-.24-2.86-.16-.78-.36-1.41-.62-1.9-.25-.49-.54-.84-.87-1.06-.33-.22-.66-.33-.99-.33-.44 0-.8.17-1.09.5-.29.34-.53.79-.72 1.36-.18.57-.32 1.24-.4 2a23.4 23.4 0 00-.13 2.45c0 .77.03 1.57.09 2.39a47.982 47.982 0 00.59 4.78c.13.76.28 1.45.43 2.09L0 18.65V1.43h3.8V15.1l2.84.28c-.14-.8-.24-1.69-.33-2.65-.08-.96-.13-1.95-.13-2.96 0-1.21.09-2.39.26-3.56.19-1.17.5-2.21.93-3.13.44-.91 1.04-1.66 1.8-2.23C9.94.29 10.92 0 12.11 0"
      fill="#c10230"
    />
    <path
      className="logo__st2"
      d="M17.62 28.06c0 .97-.75 1.76-1.76 1.76-.97 0-1.76-.78-1.76-1.76 0-1.01.79-1.79 1.76-1.79 1.01-.01 1.76.78 1.76 1.79M10.46 28.06c0 .97-.75 1.76-1.76 1.76-.97 0-1.76-.78-1.76-1.76 0-1.01.79-1.79 1.76-1.79 1.02-.01 1.76.78 1.76 1.79M3.52 28.06c0 .97-.75 1.76-1.76 1.76-.97 0-1.76-.78-1.76-1.76 0-1.01.79-1.79 1.76-1.79 1.01-.01 1.76.78 1.76 1.79"
    />
  </svg>
);

export default Logo;
